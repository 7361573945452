// write a HOC layout
import React from 'react';
import Navbar from '../common/Navbar';
import Footer from '../common/Footer';
import { Outlet } from 'react-router-dom';  // Outlet is used to render the child route
import "../../assets/styles/layouts/home-layout.scss";

const HomeLayout = () => {
    return (
        <>
            <Navbar />
            <div className="home-layout">
            <Outlet />
            </div>
            
            <Footer />
        </>
    );
};

export default HomeLayout;