import React from "react";
import "../assets/styles/pages/CorporateUserDetails.scss";
import downloadIcon from "../assets/images/instruction page/instruction-downlaod.png";
import installIcon from "../assets/images/instruction page/install.png";
import signupIcon from "../assets/images/instruction page/signup.png";

const CorporateUserDetails = () => {
  return (
    <div className="page_wrapper">
      <section className="details_section">
        <div className="container">
          <div
            className="col-lg-6 col-md-12 sub-section-left"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <div className="inner-sub-section-left">
            <div className="banner_text">
              <div className="type-wrap">
                <span
                  id="typed"
                  style={{ whiteSpace: "pre" }}
                  className="typed"
                ></span>
              </div>
              {/* h1 */}
              <h1>
                Welcome to <span>Antro:</span>
              </h1>
              <p>
                We’re excited to have you on board. Below are some simple steps
                to help you get started with our app:
              </p>
              <p>
                We’re thrilled to have you as a part of our community. With
                Antro, you’ll enjoy a seamless experience designed to make your
                life easier. From making friends to knowing them, we’re here to
                help you every step of the way!
              </p>
            </div>
            <div className="used_app">
              
            </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 sub-section-right">
          <div className="inner-sub-section-right">
            <div className="banner_slider">
              <div className="item">
                <div className="card">
                  <div className="card-icon">
                    <img src={downloadIcon} alt="Download Icon" />
                  </div>
                  <h3>Step 1: Download the App</h3>
                  <p>
                    Visit the App Store/Google Play on your mobile device.
                    Search for “Antro” and download the app.
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="card">
                  <div className="card-icon">
                    <img src={installIcon} alt="Install Icon" />
                  </div>
                  <h3>Step 2: Install the App</h3>
                  <p>
                    Once the download is complete, tap on the install button and
                    follow the on-screen prompts.
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="card">
                  <div className="card-icon">
                    <img src={signupIcon} alt="Sign Up Icon" />
                  </div>
                  <h3>Step 3: Sign Up or Log In</h3>
                  <p>
                    Open the app after installation, and either sign up for a
                    new account or log in with your existing credentials.
                  </p>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CorporateUserDetails;
