import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../assets/styles/pages/About.scss";
import RectangleImage from "../assets/images/Rectangle 25.png";
import LeftDiv from "../assets/images/AboutUs/left-div.png";
import TopMiddleDiv from "../assets/images/AboutUs/top-middle.png";
import BottomLeftDiv from "../assets/images/AboutUs/bottom-left.png";
import BottomRightDiv from "../assets/images/AboutUs/bottom-right.png";
import RightDiv from "../assets/images/AboutUs/right-div.png";
import ProtectingIdentities from "../assets/images/AboutUs/ProtectingIdentities.png";
import GlobalVulnerabilities from "../assets/images/AboutUs/GlobalVulnerabilities.png";
import HeadingFrame from "../assets/images/AboutUs/AboutUsFrame.png";
import FrameImage from "../assets/images/Frame.png";
import RajendraGuptaImage from "../assets/images/Rajendra-gupta.png";
import VartikaGuptaImage from "../assets/images/vratika-gupta.png";
import SushilKrishnaSahooImage from "../assets/images/sushil-krishna.png";
import LinkedInIcon from "../assets/images/linkedin.png";

const About = () => {
  // Scroll to top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="about-us-section">
      <div className="About-us-frame"><img src={HeadingFrame} /></div>

      <div className="header-about-us">
        <div className="heading-header-about-us">About Us.</div>
        <div className="sub-heading-header-about-us">
          This is the story about us.
        </div>
      </div>

      <div className="grid-container">
        <div className="left">
          <img src={LeftDiv} />
        </div>
        <div className="middle">
          <div className="top">
            <img src={TopMiddleDiv} />
          </div>
          <div className="bottom">
            <div className="bottom-left">
              <img src={BottomLeftDiv} />
            </div>
            <div className="bottom-right">
              <img src={BottomRightDiv} />
            </div>
          </div>
        </div>
        <div className="right">
          <img src={RightDiv} />
        </div>
      </div>


      <div className="sub-header-about-us">
        <div className="left-header-content">
          <div className="header-title-subtitle">
            <span className="header-title">Antro:</span>
            <span className="header-subtitle">
              <nbsp />
              Redefining Security, Empowering Futures
            </span>
          </div>
        </div>
        <div className="right-header-content">
          <div className="right-header-content-content">
            Antro is a forward-thinking cybersecurity firm specializing in
            identity and access management solutions for both B2B and B2C
            customers. Our focus is on providing real-time, tailored solutions
            to address specific challenges faced by our clients. We believe in
            integrating people, processes, and technology to reduce global
            vulnerability. By leveraging emerging technologies and innovative
            solutions, we've shifted the paradigm of cyber-attacks.
          </div>
        </div>
      </div>

      {/* Mission Section */}
      <div className="our-mission-main">
        <div className="our-mission">
          <div className="our-mission-content">
            <div className="heading-our-mission-content">
              <div className="heading-testimonial-about-us">
                <span>OUR COMMITMENT</span>
              </div>
              <div className="content-testimonial-about-us">
                Gaurdians of Trust: Our Mission at Antro
              </div>
            </div>
            <div className="mission-content-main">
              <div className="mission-content-1">
                <div className="left-image">
                  <div className="left-image-content">
                    <img
                      className="reactangle"
                      src={ProtectingIdentities}
                      alt="reactangle"
                    />
                  </div>
                </div>

                <div className="mission-item">
                  <div className="mission-item-heading">
                    <div className="mission-item-heading-parts">
                      Protecting Identities, Preserving Trust
                    </div>
                  </div>
                  <div className="story-content">
                    <div className="mission-item-content">
                      At Antro, we safeguard identities with vigilance, ensuring
                      trust remains unshaken in every interaction.
                    </div>
                  </div>
                </div>
              </div>
              <div className="mission-content-2">
                <div className="right-image">
                  <div className="right-image-content">
                    <img
                      className="reactangle"
                      src={GlobalVulnerabilities}
                      alt="reactangle"
                    />
                  </div>
                </div>
                <div className="mission-item">
                  <div className="mission-item-heading">
                    <div className="mission-item-heading-parts">
                      Minimizing Global Vulnerabilities
                    </div>
                  </div>
                  <div className="story-content">
                    <div className="mission-item-content">
                      Our mission extends beyond borders as we strive to reduce
                      vulnerabilities worldwide, fortifying digital landscapes
                      against emerging threats.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Lobby of Directors Section */}
      <div className="directors-section">
        <div className="directors-container">
          <div className="directors">
            <div className="heading-testimonial-about-us">
              <span>LEADERSHIP TEAM</span>
            </div>
            <div className="content-testimonial-about-us">
              Lobby of Directors
            </div>
            <div className="main-directors-carousel">
              <div className="directors-carousel">
                <div className="carousel-item-content" style={{width: 'auto', maxWidth: 'none'}}>
                  <div className="carousel-item-content-heading">- Meet our Leadership -</div>
                  <div className="carousel-item-content-sub-heading">
                    "Our leadership team consists of experienced professionals committed to steering Antro towards innovation and excellence. With a deep understanding of the industry, they guide our vision, ensuring trust and security in every step we take."
                  </div>
                  <div className="carousel-item-content-number">03</div>
                </div>
                <div className="carousel-item">
                  <div className="carousel-image">
                    <img
                      src={RajendraGuptaImage}
                      alt="Rajendra Gupta"
                      className="director-image"
                    />
                  </div>
                  <div className="carousel-info-director">
                    <div className="name">Rajendra Gupta</div>
                    <div className="info">
                      <p>Co-Founder & CEO</p>
                      <a href="https://www.linkedin.com/in/rajendragupta">
                        <img src={LinkedInIcon} alt="LinkedIn" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="carousel-image">
                    <img
                      src={VartikaGuptaImage}
                      alt="Rajendra Gupta"
                      className="director-image"
                    />
                  </div>
                  <div className="carousel-info-director">
                    <div className="name">Vartika Gupta</div>
                    <div className="info">
                      <p>Co-Founder, Director & COO</p>
                      <a href="https://www.linkedin.com/in/vartikagupta">
                        <img src={LinkedInIcon} alt="LinkedIn" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="carousel-image">
                    <img
                      src={SushilKrishnaSahooImage}
                      alt="Sushil Krishna Sahoo"
                      className="director-image"
                    />
                  </div>
                  <div className="carousel-info-director">
                    <div className="name">Sushil Krishna Sahoo</div>
                    <div className="info">
                      <p>Co-Founder & CTO</p>
                      <a href="https://www.linkedin.com/in/sushilkrishnasahoo">
                        <img src={LinkedInIcon} alt="LinkedIn" />
                      </a>
                    </div>
                  </div>
                </div>
                {/* <div className="carousel-item">
                <div className="carousel-image">
                  <img
                    src={SushilKrishnaSahooImage}
                    alt="Sushil Krishna Sahoo"
                    className="director-image"
                  />
                </div>
                <div className="carousel-info-director">
                  <div className="name">Sushil Krishna Sahoo</div>
                  <div className="info">
                    <p>Co-Founder & CTO</p>
                    <a href="https://www.linkedin.com/in/sushilkrishnasahoo">
                      <img src={LinkedInIcon} alt="LinkedIn" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="carousel-image">
                  <img
                    src={SushilKrishnaSahooImage}
                    alt="Sushil Krishna Sahoo"
                    className="director-image"
                  />
                </div>
                <div className="carousel-info-director">
                  <div className="name">Sushil Krishna Sahoo</div>
                  <div className="info">
                    <p>Co-Founder & CTO</p>
                    <a href="https://www.linkedin.com/in/sushilkrishnasahoo">
                      <img src={LinkedInIcon} alt="LinkedIn" />
                    </a>
                  </div>
                </div>
              </div> */}

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
