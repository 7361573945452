import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import Saas1 from '../../assets/images/portfolio/saas1.png';
import Saas2 from '../../assets/images/portfolio/saas2.png';
import Saas3 from '../../assets/images/portfolio/saas3.png';
import Saas4 from '../../assets/images/portfolio/saas4.png';
import '../../assets/styles/pages/services/SaaSOfferings.scss';

const offerings = [
  {
    title: 'Dedicated Servers',
    description: `
      - High-performance bare metal servers.
      - Full root access and customization options.
      - Ideal for resource-intensive applications.
    `,
    image: Saas1,
  },
  {
    title: 'Virtual Private Servers (VPS)',
    description: `
      - Scalable virtualized environments.
      - Isolated resources for enhanced security.
      - Cost-effective solution for growing businesses.
    `,
    image: Saas2,
  },
  {
    title: 'Cloud Servers',
    description: `
      - On-demand computing resources.
      - Pay-as-you-go pricing model.
      - Seamless scaling to match workload fluctuations.
    `,
    image: Saas3,
  },
  {
    title: 'Managed Hosting',
    description: `
      - Fully managed server solutions.
      - 24/7 monitoring and support.
      - Regular backups and security updates.
    `,
    image: Saas4,
  },
];

const SaaSOfferings = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false, amount: 0.2 });

  return (
    <div className="saas-offerings" ref={ref}>
      <motion.h2
        initial={{ opacity: 0, y: -50 }}
        animate={isInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1 }}
        className="saas-offerings__heading"
      >
        Software as a Service (SaaS) Offerings
      </motion.h2>
      <p className="saas-offerings__subheading">
        We provide a range of flexible server solutions to meet diverse business needs
      </p>

      <div className="saas-offerings__grid">
        {offerings.map((offer, index) => (
          <motion.div
            key={index}
            className="saas-offering-item"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={isInView ? { opacity: 1, scale: 1 } : {}}
            transition={{ delay: 0.2 * index, duration: 0.5 }}
            whileHover={{ scale: 1.05 }}
          >
            <div className="saas-offering-item__image">
              <img src={offer.image} alt={offer.title} />
            </div>
            <div className="saas-offering-item__content">
              <h3>{offer.title}</h3>
              <p>{offer.description}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default SaaSOfferings;
