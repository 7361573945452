// Create a Home page
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import '../assets/styles/pages/Home.scss';
// import Carousel from 'react-multi-carousel';
import VerticalCarousel from '../components/common/Carousel';
import 'react-multi-carousel/lib/styles.css';
import HomeRectangleImg from "../assets/images/home-rectangle.png"
import HomeCirlceImg from "../assets/images/home-circle.png"
import JqueryLogo from "../assets/images/jquery-1.png"
import PaypalLogo from "../assets/images/paypal-1.png"
import ShopbooatLogo from "../assets/images/shopboat-1.png"
import ThemerForentLogo from "../assets/images/themeforest.png"
import WooCommerceLogo from "../assets/images/woocommerce-1.png"
import SlackLogo from "../assets/images/slack-1.png"
import Section2Security1 from "../assets/images/homepage/Frame 626935.png"
import Section2Security2 from "../assets/images/homepage/section-2-security-2.png"
import Section2Security3 from "../assets/images/homepage/image1.png"
import Section2Security4 from "../assets/images/homepage/image2.png"
import Section2Security5 from "../assets/images/homepage/image3.webp"
import Section2Security6 from "../assets/images/homepage/image4.webp"
// import Section2Security7 from "../assets/images/homepage/image5.webp"
// import Section2Security8 from "../assets/images/homepage/image6.webp"
import Section2Security9 from "../assets/images/homepage/image7.webp"
import Section2Security10 from "../assets/images/homepage/image8.jpeg"
import Section2Security11 from "../assets/images/homepage/image9.png"
import IdentityManagement1 from "../assets/images/homepage/identity_management_1.png"
import IdentityManagement2 from "../assets/images/homepage/identity_management_2.png"
import IdentityManagement3 from "../assets/images/homepage/identity_management_3.png"
import IdentityManagement4 from "../assets/images/homepage/identity_management_4.png"
import IdentityManagement5 from "../assets/images/homepage/identity_management_5.png"
import IdentityManagement6 from "../assets/images/homepage/identity_management_6.png"
import NewsSub1 from "../assets/images/homepage/news_sub_1.png"
import NewsSub2 from "../assets/images/homepage/news_sub_2.png"
import NewsSub3 from "../assets/images/homepage/news_sub_3.png"
import TestImg1 from "../assets/images/homepage/TESTIMONIALS/testimonial-image-1.png"
import TestImg2 from "../assets/images/homepage/TESTIMONIALS/testimonial-image-2.png"
import TestImg3 from "../assets/images/homepage/TESTIMONIALS/testimonial-image-3.png"
import TestImg4 from "../assets/images/homepage/TESTIMONIALS/testimonial-image-4.png"
import TestImg5 from "../assets/images/homepage/TESTIMONIALS/testimonial-image-5.png"
import TestImg6 from "../assets/images/homepage/TESTIMONIALS/testimonial-image-6.png"
import Search from "../assets/images/homepage/TESTIMONIALS/Search.png"
import Clock from "../assets/images/homepage/ph_clock.png"
import Location from "../assets/images/homepage/ep_location.png"
import ImageCarousel from '../components/common/Carousel1';
import { LuBadgeCheck } from "react-icons/lu";
import Headphones from "../assets/images/homepage/bx_support.png"
import { GoShieldCheck } from "react-icons/go";
import { FaPlay } from "react-icons/fa";
import { FaGears } from "react-icons/fa6";
import { TfiHeadphoneAlt } from "react-icons/tfi";



const Home = () => {
  const navigate = useNavigate();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  const scrollRef = useRef(null);

  useEffect(() => {
    const scrollContainer = scrollRef.current;
  if (scrollContainer) {
    const scroll = () => {
      scrollContainer.scrollLeft += 1;
      if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth - scrollContainer.clientWidth) {
        scrollContainer.scrollLeft = 0;
      }
    };

    const intervalId = setInterval(scroll, 20); // Adjust the interval to control speed

    return () => clearInterval(intervalId); // Cleanup
  }
  }, []);

  const blogPostsItems = [
    <div className="small-post">
      <div className="post-image-placeholder">
        <img src={IdentityManagement3} />
      </div>
      <div className="post-content">
        <p className="post-date">July 10, 2024</p>
        <h3 className="post-title">
        The Future of Identity Protection: Trends Shaping Cybersecurity
        </h3>
      </div>
    </div>,
    <div className="small-post">
      <div className="post-image-placeholder">
        <img src={IdentityManagement4} />
      </div>
      <div className="post-content">
        <p className="post-date">July 10, 2024</p>
        <h3 className="post-title">
        5 Key Cybersecurity Practices Every Business Should Adopt
        </h3>
      </div>
    </div>,
    <div className="small-post">
      <div className="post-image-placeholder">
        <img src={IdentityManagement5} />
      </div>
      <div className="post-content">
        <p className="post-date">July 10, 2024</p>
        <h3 className="post-title">
        The Role of AI in Strengthening Digital Security
        </h3>
      </div>
    </div>,
    <div className="small-post">
      <div className="post-image-placeholder">
        <img src={IdentityManagement6} />
      </div>
      <div className="post-content">
        <p className="post-date">July 10, 2024</p>
        <h3 className="post-title">
        Understanding Zero Trust: A New Approach to Cyber Defense
        </h3>
      </div>
    </div>
  ];

  const newsPostsItems = [
    <div className="small-post">
      <div className="post-image-placeholder">
        <img src={NewsSub1} />
      </div>
      <div className="post-content">
        <p className="post-insights">Industry Insights</p>
        <h3 className="post-title">
          Understanding CCPA: Best Practices for Data Protection and Compliance
        </h3>
        <p className="post-date">July 10, 2024 <span><FaPlay /> Play</span></p>
      </div>
    </div>,
    <div className="small-post">
      <div className="post-image-placeholder">
        <img src={NewsSub2} />
      </div>
      <div className="post-content">
        <p className="post-insights">Industry Insights</p>
        <h3 className="post-title">
          Mitigating Insider Threats: Effective Identity Governance Strategies
        </h3>
        <p className="post-date">July 10, 2024 <span><FaPlay /> Play</span></p>
      </div>
    </div>,
    <div className="small-post">
      <div className="post-image-placeholder">
        <img src={NewsSub3} />
      </div>
      <div className="post-content">
        <p className="post-insights">Industry Insights</p>
        <h3 className="post-title">
          Biometric Authentication: Balancing Convenience and Security
        </h3>
        <p className="post-date">July 10, 2024 <span><FaPlay /> Play</span></p>
      </div>
    </div>
  ];

  return (
    <div className="home">
      <div className='section-1'>
        <div>
          <ImageCarousel />
        </div>
      </div>
      {/* <div className="trusted-partners-container">
        <h2 className='trusted-by-heading'>
          Trusted by Leading Companies Worldwide
        </h2>
        <div className="partners-logos-wrapper">
          <div className="partners-logos marquee" ref={scrollRef}>
            <div className="partner-logo"><img src={WooCommerceLogo} alt="WooCommerce" /></div>
            <div className="partner-logo"><img src={JqueryLogo} alt="jQuery" /></div>
            <div className="partner-logo"><img src={ThemerForentLogo} alt="ThemeForest" /></div>
            <div className="partner-logo"><img src={PaypalLogo} alt="PayPal" /></div>
            <div className="partner-logo"><img src={ShopbooatLogo} alt="Shopbot" /></div>
            <div className="partner-logo"><img src={JqueryLogo} alt="jQuery" /></div>
            <div className="partner-logo"><img src={WooCommerceLogo} alt="WooCommerce" /></div>
            <div className="partner-logo"><img src={SlackLogo} alt="Slack" /></div>
          </div>
        </div>
      </div> */}
      <div className="cybersecurity-partner-container">
        <div className="text-section">
          <div className='text-section-inner'>
            <button className="about-us-btn" onClick={() => navigate("/about-us")}>About Us</button>
            <h2>Securing Tomorrow, Today: Meet Antro, Your Cybersecurity Partner</h2>
            <p>
              An innovative cybersecurity solutions company specializing in identity and access management. We offer real-time,
              process-driven solutions designed to protect against identity theft and impersonation.
            </p>
          </div>
        </div>
        <div className="image-section">
          <div className="image-box">
            <img src={Section2Security2} />
          </div>
          {/* <div className="image-box image-box-right">
            <img src={Section2Security1} />
          </div> */}
        </div>

      </div>
      <div className="section-4-container">
        <div className="text-section">
          <div className='text-section-inner'>
          <div className='heading-testimonial-home'>
          <span>EXPLORE</span>
          </div>
            <div className='heading-testimonial-home-sub'>Unveiling Antro PAI: Your Shield Against Identity Threats</div>
            <p>
              Antro's Pre-Authenticated Identity (PAI) model ensures unique,
              dynamic identity verification, preventing fraud and unauthorized access.
            </p>
          </div>
        </div>
        <div className="image-section">
          <div className='image-section-1'>
            <div className="image-box">
              <div className='image-box-heading'>
                <div className='heading-icon'><LuBadgeCheck /></div>
                <div className='heading-text'>Dynamic Identity Verification:</div>
              </div>
              <div className='heading-para'>
                Continuously evolves to match the latest security protocols, offering adaptive measures for each transaction.
              </div>
            </div>
            <div className="image-box">
              <div className='image-box-heading'>
                <div className='heading-icon'><FaGears /></div>
                <div className='heading-text'>Seamless Integration:</div>
              </div>
              <div className='heading-para'>
                Easily integrates with your existing systems, enhancing security without disrupting your workflow.
              </div>
            </div>
            <div className="image-box">
              <div className='image-box-heading'>
                <div className='heading-icon'><GoShieldCheck /></div>
                <div className='heading-text'>Proactive Fraud Prevention:</div>
              </div>
              <div className='heading-para'>
                Detects and prevents unauthorized access with real-time monitoring and alerts, safeguarding your data and assets.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-posts-container">
        <div className="header">
        <div className='heading-testimonial-home'>
          INSIGHT VAULT
          </div>
          <h1>Identity Management</h1>
        </div>

        <div className="latest-posts-section">
          <div className='section-title'>Our Latest Blog Posts</div>

          <div className="posts-grid">
            {/* Main post cards */}
            <div className='large-post-outer'>
              <div className="large-post">
                <div className="post-image-placeholder">
                  <img src={IdentityManagement1} />
                </div>
                <div className="post-content">
                  <p className="post-date">July 10, 2024</p>
                  <h3 className="post-title">
                    The Future Of Identity Management: Trends To Watch In 2024
                  </h3>
                  <p className="post-description">
                    Exploring the latest innovations and what they mean for your business.
                  </p>
                </div>
              </div>

              <div className="large-post">
                <div className="post-image-placeholder">
                  <img src={IdentityManagement2} />
                </div>
                <div className="post-content">
                  <p className="post-date">July 10, 2024</p>
                  <h3 className="post-title">
                    Strengthening Your Cybersecurity With Biometric Authentication
                  </h3>
                  <p className="post-description">
                    How biometric solutions are revolutionizing identity verification.
                  </p>
                </div>
              </div>
            </div>

            <div className="small-posts">
              {blogPostsItems.map(item => {
                return item
              })}
              {/* <VerticalCarousel items={blogPostsItems} showControls={false} /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="news-updates-container">
        <div className="latest-posts-section">
          <div className='section-title'>News & Updates</div>

          <div className="posts-grid">
            {/* Main post cards */}
            <div className='large-post-outer'>
              <div className="large-post">
                <div className="post-image-placeholder">
                  <img src={Section2Security1} />
                </div>
                <div className="post-content">
                  <div>
                    <p className="post-insights">Industry Insights</p>
                  </div>
                  <div>
                    <h3 className="post-title">
                      The Future Of Identity Management: Trends To Watch In 2024
                    </h3>
                    <p className="post-description">
                      Exploring the latest innovations and what they mean for your business.
                    </p>
                  </div>
                  <div>
                    <p className="post-date">July 10, 2024</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="small-posts">
              {newsPostsItems.map(item => {
                return item
              })}
              {/* <VerticalCarousel items={newsPostsItems} showControls={false} /> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="upcoming-events-container">
        <div className="latest-posts-section">
          <div className='section-title'>Upcoming Events for You</div>
          <div className="card-container">
            <div className="card">
              <div className="card-image">
                <img src={TestImg1}/>
              </div>
              <div className="card-content">
                <div className="date-section-outer">
                  <div className="date-section">
                    <span className="month">JULY</span>
                    <span className="day">16</span>
                  </div>
                  <div className='description-outer'>
                    <h3 className="event-title">Cybersecurity Summit 2024</h3>
                    <div className="event-description">
                      Join industry leaders to discuss the latest trends in cybersecurity and identity management.
                    </div>
                  </div>
                </div>
                <div className="event-info">
                  <div className='event-info-sub'><span className="event-info-icon"><img src={Location}/></span><span className='event-info-text'> New York, NY</span></div>
                  <div className='event-info-sub'><span className="event-info-icon"><img src={Clock}/></span> <span className='event-info-text'>10:00 AM - 3:00 PM</span></div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-image">
              <img src={TestImg2}/>
              </div>
              <div className="card-content">
                <div className="date-section-outer">
                  <div className="date-section">
                    <span className="month">JULY</span>
                    <span className="day">16</span>
                  </div>
                  <div className='description-outer'>
                    <h3 className="event-title">Cybersecurity Summit 2024</h3>
                    <div className="event-description">
                      Join industry leaders to discuss the latest trends in cybersecurity and identity management.
                    </div>
                  </div>
                </div>
                <div className="event-info">
                  <div className='event-info-sub'><span className="event-info-icon"><img src={Location}/></span><span className='event-info-text'> New York, NY</span></div>
                  <div className='event-info-sub'><span className="event-info-icon"><img src={Clock}/></span> <span className='event-info-text'>10:00 AM - 3:00 PM</span></div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-image">
              <img src={TestImg3}/>
              </div>
              <div className="card-content">
                <div className="date-section-outer">
                  <div className="date-section">
                    <span className="month">JULY</span>
                    <span className="day">16</span>
                  </div>
                  <div className='description-outer'>
                    <h3 className="event-title">Cybersecurity Summit 2024</h3>
                    <div className="event-description">
                      Join industry leaders to discuss the latest trends in cybersecurity and identity management.
                    </div>
                  </div>
                </div>
                <div className="event-info">
                  <div className='event-info-sub'><span className="event-info-icon"><img src={Location}/></span><span className='event-info-text'> New York, NY</span></div>
                  <div className='event-info-sub'><span className="event-info-icon"><img src={Clock}/></span> <span className='event-info-text'>10:00 AM - 3:00 PM</span></div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-image">
              <img src={TestImg4}/>
              </div>
              <div className="card-content">
                <div className="date-section-outer">
                  <div className="date-section">
                    <span className="month">JULY</span>
                    <span className="day">16</span>
                  </div>
                  <div className='description-outer'>
                    <h3 className="event-title">Cybersecurity Summit 2024</h3>
                    <div className="event-description">
                      Join industry leaders to discuss the latest trends in cybersecurity and identity management.
                    </div>
                  </div>
                </div>
                <div className="event-info">
                  <div className='event-info-sub'><span className="event-info-icon"><img src={Location}/></span><span className='event-info-text'> New York, NY</span></div>
                  <div className='event-info-sub'><span className="event-info-icon"><img src={Clock}/></span> <span className='event-info-text'>10:00 AM - 3:00 PM</span></div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-image">
              <img src={TestImg5}/>
              </div>
              <div className="card-content">
                <div className="date-section-outer">
                  <div className="date-section">
                    <span className="month">JULY</span>
                    <span className="day">16</span>
                  </div>
                  <div className='description-outer'>
                    <h3 className="event-title">Cybersecurity Summit 2024</h3>
                    <div className="event-description">
                      Join industry leaders to discuss the latest trends in cybersecurity and identity management.
                    </div>
                  </div>
                </div>
                <div className="event-info">
                  <div className='event-info-sub'><span className="event-info-icon"><img src={Location}/></span><span className='event-info-text'> New York, NY</span></div>
                  <div className='event-info-sub'><span className="event-info-icon"><img src={Clock}/></span> <span className='event-info-text'>10:00 AM - 3:00 PM</span></div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-image">
              <img src={TestImg6}/>
              </div>
              <div className="card-content">
                <div className="date-section-outer">
                  <div className="date-section">
                    <span className="month">JULY</span>
                    <span className="day">16</span>
                  </div>
                  <div className='description-outer'>
                    <h3 className="event-title">Cybersecurity Summit 2024</h3>
                    <div className="event-description">
                      Join industry leaders to discuss the latest trends in cybersecurity and identity management.
                    </div>
                  </div>
                </div>
                <div className="event-info">
                  <div className='event-info-sub'><span className="event-info-icon"><img src={Location}/></span><span className='event-info-text'> New York, NY</span></div>
                  <div className='event-info-sub'><span className="event-info-icon"><img src={Clock}/></span> <span className='event-info-text'>10:00 AM - 3:00 PM</span></div>
                </div>
              </div>
            </div>


          </div>
          <button className="cta-button" onClick={() => navigate("/contact-us")}><span className='search-icon'><img src={Search}/></span>Explore More</button>
        </div>
      </div> */}
      {/* <div className='main-testimonial-posts-container'>
      <div className="testimonial-posts-container">
        <div className="header">
          <div className='heading-testimonial-home'>
          <span>TESTIMONIAL</span>
          </div>
          <div className='sub-heading-testimonial-home'>Voices of Trust: Antro in the Words of Our <span className='sub-heading-part-one-testimonial-home'>Clients</span></div>
        </div>
        <div className="latest-posts-section">
          <div className="testimonial-slider">
            <div className="slider-track">
              <div className="testimonial">
                <p className="quote">“This service has dramatically improved our team's productivity. The collaborative tools are second to none!”</p>
                <div className="user-info">
                  <img src="https://i.pravatar.cc/50?img=1" alt="User Image" />
                  <div className="details">
                    <div className="details-inner">
                      <h4>Michael Smith</h4>
                      <div className="designation">Software Engineer</div>
                    </div>
                    <div className="rating">★★★★★</div>
                  </div>
                </div>
              </div>

              <div className="testimonial">
                <p className="quote">“An essential tool for any professional. It has streamlined our processes and improved efficiency across the board.”</p>
                <div className="user-info">
                  <img src="https://i.pravatar.cc/50?img=2" alt="User Image" />
                  <div className="details">
                    <div className="details-inner">
                      <h4>Sarah Thompson</h4>
                      <div className="designation">Graphic Designer</div>
                    </div>
                    <div className="rating">★★★★☆</div>
                  </div>
                </div>
              </div>

              <div className="testimonial">
                <p className="quote">“I love how intuitive and user-friendly the platform is. Our project timelines have never been clearer.”</p>
                <div className="user-info">
                  <img src="https://i.pravatar.cc/50?img=3" alt="User Image" />
                  <div className="details">
                    <div className="details-inner">
                      <h4>David Williams</h4>
                      <div className="designation">Product Manager</div>
                    </div>
                    <div className="rating">★★★★★</div>
                  </div>
                </div>
              </div>

              <div className="testimonial">
                <p className="quote">“A game-changer in project management. The features are exactly what we needed to keep everything organized.”</p>
                <div className="user-info">
                  <img src="https://i.pravatar.cc/50?img=4" alt="User Image" />
                  <div className="details">
                    <div className="details-inner">
                      <h4>Jessica Brown</h4>
                      <div className="designation">Data Analyst</div>
                    </div>
                    <div className="rating">★★★★★</div>
                  </div>
                </div>
              </div>

              <div className="testimonial">
                <p className="quote">“Switching to this platform was the best decision we've made this year. Highly recommend it!”</p>
                <div className="user-info">
                  <img src="https://i.pravatar.cc/50?img=5" alt="User Image" />
                  <div className="details">
                    <div className="details-inner">
                      <h4>Daniel Jones</h4>
                      <div className="designation">UX/UI Designer</div>
                    </div>
                    <div className="rating">★★★★☆</div>
                  </div>
                </div>
              </div>

              <div className="testimonial">
                <p className="quote">“The customer support is outstanding. They helped us integrate the tool seamlessly into our workflow.”</p>
                <div className="user-info">
                  <img src="https://i.pravatar.cc/50?img=6" alt="User Image" />
                  <div className="details">
                    <div className="details-inner">
                      <h4>Laura Garcia</h4>
                      <div className="designation">HR Manager</div>
                    </div>
                    <div className="rating">★★★★★</div>
                  </div>
                </div>
              </div>

            </div>
            <div className="slider-track">
              <div className="testimonial">
                <p className="quote">“Our team collaboration has improved immensely since we started using this platform. It's a must-have!”</p>
                <div className="user-info">
                  <img src="https://i.pravatar.cc/50?img=7" alt="User Image" />
                  <div className="details">
                    <div className="details-inner">
                      <h4>James Martinez</h4>
                      <div className="designation">Sales Executive</div>
                    </div>
                    <div className="rating">★★★★★</div>
                  </div>
                </div>
              </div>

              <div className="testimonial">
                <p className="quote">“The analytics provided have given us valuable insights into our operations. We can now make data-driven decisions with confidence.”</p>
                <div className="user-info">
                  <img src="https://i.pravatar.cc/50?img=8" alt="User Image" />
                  <div className="details">
                    <div className="details-inner">
                      <h4>Sophia Rodriguez</h4>
                      <div className="designation">Content Strategist</div>
                    </div>
                    <div className="rating">★★★★☆</div>
                  </div>
                </div>
              </div>

              <div className="testimonial">
                <p className="quote">“I appreciate how customizable the platform is. It fits perfectly with our unique workflow.”</p>
                <div className="user-info">
                  <img src="https://i.pravatar.cc/50?img=9" alt="User Image" />
                  <div className="details">
                    <div className="details-inner">
                      <h4>Robert Hernandez</h4>
                      <div className="designation">Operations Manager</div>
                    </div>
                    <div className="rating">★★★★★</div>
                  </div>
                </div>
              </div>

              <div className="testimonial">
                <p className="quote">“The platform's interface is sleek and easy to navigate. It has significantly reduced our training time for new hires.”</p>
                <div className="user-info">
                  <img src="https://i.pravatar.cc/50?img=10" alt="User Image" />
                  <div className="details">
                    <div className="details-inner">
                      <h4>Olivia Lopez</h4>
                      <div className="designation">Financial Advisor</div>
                    </div>
                    <div className="rating">★★★★☆</div>
                  </div>
                </div>
              </div>

              <div className="testimonial">
                <p className="quote">“An invaluable tool for customer relationship management. Our clients are happier than ever.”</p>
                <div className="user-info">
                  <img src="https://i.pravatar.cc/50?img=11" alt="User Image" />
                  <div className="details">
                    <div className="details-inner">
                      <h4>William Wilson</h4>
                      <div className="designation">Customer Success Manager</div>
                    </div>
                    <div className="rating">★★★★★</div>
                  </div>
                </div>
              </div>

              <div className="testimonial">
                <p className="quote">“The insights we gain from this platform have transformed our marketing strategies.”</p>
                <div className="user-info">
                  <img src="https://i.pravatar.cc/50?img=12" alt="User Image" />
                  <div className="details">
                    <div className="details-inner">
                      <h4>Emma Anderson</h4>
                      <div className="designation">Business Analyst</div>
                    </div>
                    <div className="rating">★★★★★</div>
                  </div>
                </div>
              </div>

              <div className="testimonial">
                <p className="quote">“Our online presence has grown exponentially thanks to the tools provided. Can't recommend it enough!”</p>
                <div className="user-info">
                  <img src="https://i.pravatar.cc/50?img=13" alt="User Image" />
                  <div className="details">
                    <div className="details-inner">
                      <h4>Joseph Taylor</h4>
                      <div className="designation">Digital Marketer</div>
                    </div>
                    <div className="rating">★★★★☆</div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      </div> */}
      <div className='main-connect-section'>
      <section className="connect-section">
        <div className="icon">
        <img src={Headphones}/>
        </div>
        <div className='connect-section-heading'>Connect with Confidence</div>
        <p>Have questions or need support? Contact our team for assistance. Feel free to reach out for expert guidance and personalized assistance.</p>
        <button className="cta-button" onClick={() => navigate("/contact-us")}>Reach Out!</button>
      </section>
    </div> 
    </div> 

  );
}

export default Home;