// Create a not found page
import React from 'react';
import { Link } from 'react-router-dom';
import AntroLogo from "../assets/images/antrologo.png"
// import PadlockIcon from "../assets/images/Padlock.svg"
import '../assets/styles/pages/UpdateUsername.scss';

const NotFound = () =>  {
    return (
        <div className="main-update-username">
        <div className="header">
          <img src={AntroLogo} alt="Antro Logo" className="logo" />
        </div>
        {/* <img src={PadlockIcon} alt="Padlock Icon" className="lock-icon" /> */}
        <div className="container">
          <h2>Page Not Found</h2>
            <Link to="/">
                <button>Go Home</button>
            </Link>
        </div>
      </div>
    );
}

export default NotFound;