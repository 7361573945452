import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import Flagship1 from '../../assets/images/portfolio/flagship1.png';
import '../../assets/styles/pages/services/FlagshipProduct.scss';

const FlagshipProduct = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false, amount: 0.5 });

  return (
    <div className="flagship-product" ref={ref}>
      <div className='flagship-sub-heading'>WHAT WE PROVIDE</div>
      <motion.h2
        initial={{ opacity: 0, y: -50 }}
        animate={isInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8 }}
      >
        Our Flagship Product: Antro
      </motion.h2>

      <div className="flagship-product__content">
        <motion.div
          className="flagship-product__text"
          initial={{ opacity: 0, x: -50 }}
          animate={isInView ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 1 }}
        >
          <p>
            <strong>Antro</strong> is our ground-breaking identity verification platform, designed to revolutionize how individuals and organisations manage digital identities.
          </p>
          <ul>
            <li>Comprehensive Digital Profiles – Detailed records of identities for individuals and organisations.</li>
            <li>Real-Time Verification – Instant identity and background checks.</li>
            <li>Network Mapping – Visualize network connections to assess risks.</li>
            <li>Risk Assessment – AI-driven analysis to flag potential identity risks.</li>
            <li>Customizable Solutions – Tailored to meet diverse enterprise and individual needs.</li>
          </ul>
        </motion.div>

        <motion.div
          className="flagship-product__image"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={isInView ? { opacity: 1, scale: 1 } : {}}
          transition={{ duration: 1.2 }}
        >
          <img src={Flagship1} alt="Antro Product" />
        </motion.div>
      </div>
    </div>
  );
}

export default FlagshipProduct;
