import React from 'react';
import { motion } from 'framer-motion';
import Header1 from '../../assets/images/portfolio/header1.png';
// import '../..assets/styles/pages/services/Header.scss';
import '../../assets/styles/pages/services/Header.scss'

const Header = () => {
  return (
    <div className="service-header">
      <div className="service-header__content">
        <div className='powered-by'>Powered By</div>  
        <motion.h1 
          initial={{ opacity: 0, y: -50 }} 
          animate={{ opacity: 1, y: 0 }} 
          transition={{ duration: 1 }}>
          Antro–PAI Technologies Private Limited
        </motion.h1>
        
        <p>Innovating Tomorrow, Today</p>
      </div>
      <motion.div 
        className="service-header__image"
        initial={{ opacity: 0, scale: 0.5 }} 
        animate={{ opacity: 1, scale: 1 }} 
        transition={{ duration: 1.5 }}>
        <img src={Header1} alt="Phone Mockup" />
      </motion.div>
    </div>
  );
}

export default Header;
