// utils/api.js
const BASE_URL = process.env.REACT_APP_BACKEND_URL;  

// Function to fetch account deletion data
export const fetchAccountDeletionData = async (deleteCode) => {
  try {
    const response = await fetch(`${BASE_URL}/accounts/deletion-data/${deleteCode}/`);
    
    if (!response.ok) {
      throw new Error("Error fetching deletion data");
    }
    
    // Assuming you need the payload from the response
    const data = await response.json();
    
    return data;  // Returning the payload
  } catch (error) {
    console.error("Error in fetchAccountDeletionData:", error);
    throw error;
  }
};

// Function to delete the account
export const deleteAccount = async (deleteCode, payload, token) => {
  try {
    const response = await fetch(
      `${BASE_URL}/accounts/delete_account/`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    
    if (!response.ok) {
      throw new Error("Error deleting account");
    }
    
    return await response.json();
  } catch (error) {
    console.error("Error in deleteAccount:", error);
    throw error;
  }
};
// write a similar login api

export const login = async (username, password) => {
  try {
    const response = await fetch(`${BASE_URL}/accounts/password-login/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });
    
    if (!response.ok) {
      throw new Error("Error logging in");
    }
    
    return await response.json();
  } catch (error) {
    console.error("Error in login:", error);
    throw error;
  }
};

// Function to check the reset link
export const  fetchResetLinkData = async (data_type,secretCode) => {
  try {
    const response = await fetch(`${BASE_URL}/accounts/check_reset_link/${secretCode}/${data_type}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    

    
    return await response.json();
  } catch (error) {
    console.error("Error in checkResetLink:", error);
    throw error;
  }
};

export const fetchJoinOrganiationLocationLinkDetails = async (secretCode) => {
  try {
    const response = await fetch(`${BASE_URL}/chats/check_chat_link/${secretCode}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    
    return await response.json();
  } catch (error) {
    console.error("Error in checkResetLink:", error);
    throw error;
  }
};

export const fetchJoinOrganisationLocations = async (organisationId) => {
  try {
    const response = await fetch(`${BASE_URL}/organisation/locations_list/${organisationId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    
    return await response.json();
  } catch (error) {
    console.error("Error in checkResetLink:", error);
    throw error;
  }
};


export const fetchJoinOrganisationLocationsInner = async (organisationId, locationId) => {
  try {
    const response = await fetch(`${BASE_URL}/organisation/locations_list/${organisationId}/${locationId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    
    return await response.json();
  } catch (error) {
    console.error("Error in checkResetLink:", error);
    throw error;
  }
};

// Function to update the password
export const updateUserDetails = async (data_type, secretCode, newValue, oldValue) => {
  try {
    const response = await fetch(`${BASE_URL}/accounts/update_reset_data/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        reset_type: data_type,
        code: secretCode,
        new: newValue,
        old: oldValue,
      }),
    });
    
    // if (!response.ok) {
    //   throw new Error("Error updating password");
    // }
    
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const JoinChatLink = async (data) => {
  try {
    const response = await fetch(`${BASE_URL}/chats/join_chat_link/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    
    // if (!response.ok) {
    //   throw new Error("Error updating password");
    // }
    
    return await response.json();
  } catch (error) {
    throw error;
  }
};

//CheckUserName
export const checkUserName = async (username) => {
  try {
    const response = await fetch(`${BASE_URL}/accounts/check_username/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username
      }),
    });

    return await response.json();
  } catch (error) {
    throw error;
  }
};

// corporate user apis to reset and check token 
export const checkLinkCorporate = async (secretCode) => {
  const response = await fetch(`${BASE_URL}/accounts/d_link_secret_details/${secretCode}/`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
  });
  const result = await response.json();
  return result;
};

export const createPasswordCorporate = async (secretCode, newPassword) =>{
  const response = await fetch (`${BASE_URL}/accounts/create_password_corporate/`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json' },
    body: JSON.stringify({secret_code: secretCode, new_password:  newPassword}),
  })
  const result = await response.json();
  return result;
}

export const contactUsApi = async (data) =>{
  const response = await fetch (`${BASE_URL}/accounts/contact-us/`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
  // const result = await response.json();
  return response;
}