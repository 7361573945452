import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import WhyChooseUs1 from '../../assets/images/portfolio/whychooseus1.png';
import WhyChooseUs2 from '../../assets/images/portfolio/whychooseus2.png';
import WhyChooseUs3 from '../../assets/images/portfolio/whychooseus3.png';
import WhyChooseUs4 from '../../assets/images/portfolio/whychooseus4.png';
import WhyChooseUs5 from '../../assets/images/portfolio/whychooseus5.png';
import WhyChooseUs6 from '../../assets/images/portfolio/whychooseus6.png';
import '../../assets/styles/pages/services/WhyChooseUs.scss';

const WhyChooseUs = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false, amount: 0.3 });

  return (
    <div className="why-choose-us" ref={ref}>
        <div>
      <div className='why-choose-us-sub-heading'>WHAT WE PROVIDE</div>
      <motion.h2 
        initial={{ opacity: 0, y: -50 }} 
        animate={isInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8 }}
        className="why-choose-us__heading"
      >
        Why Choose Antro-PAI?
      </motion.h2>
      </div>
      <div className="why-choose-us__content">
        {/* Left Column */}
        <div className="why-choose-us__column" style={{ marginTop: "50px", borderRight: "1px solid rgb(202 233 255)" }}>
          {[WhyChooseUs1, WhyChooseUs2, WhyChooseUs3].map((image, index) => (
            <motion.div
              key={index}
              className="reason-item"
              initial={{ opacity: 0, y: 50 }}
              animate={isInView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <div className="reason-item__icon">
                <img src={image} alt={`Reason ${index + 1}`} />
              </div>
              <div className="reason-item__content">
                {index === 0 && (
                  <>
                    <h3>Innovation-Driven</h3>
                    <p>Our R&D team constantly explores emerging technologies to keep you ahead of the curve.</p>
                  </>
                )}
                {index === 1 && (
                  <>
                    <h3>Customer-Centric</h3>
                    <p>We tailor our solutions to your unique business challenges and goals.</p>
                  </>
                )}
                {index === 2 && (
                  <>
                    <h3>Expertise</h3>
                    <p>With a diverse team of specialists, we bring multifaceted knowledge to every project.</p>
                  </>
                )}
              </div>
            </motion.div>
          ))}
        </div>

        {/* Right Column */}
        <div className="why-choose-us__column">
          {[WhyChooseUs4, WhyChooseUs5, WhyChooseUs6].map((image, index) => (
            <motion.div
              key={index}
              className="reason-item"
              initial={{ opacity: 0, y: 50 }}
              animate={isInView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <div className="reason-item__icon">
                <img src={image} alt={`Reason ${index + 4}`} />
              </div>
              <div className="reason-item__content">
                {index === 0 && (
                  <>
                    <h3>Comprehensive Solutions</h3>
                    <p>From identity protection to full-stack development, we're your one-stop technology partner.</p>
                  </>
                )}
                {index === 1 && (
                  <>
                    <h3>Quality Assurance</h3>
                    <p>Rigorous testing and quality control processes ensure top-notch deliverables.</p>
                  </>
                )}
                {index === 2 && (
                  <>
                    <h3>Scalability</h3>
                    <p>Our solutions grow with your business, ensuring long-term value.</p>
                  </>
                )}
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
