import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fetchAccountDeletionData, deleteAccount } from "../utils/apis";
import { login } from "../utils/apis";
import "../assets/styles/pages/AccountDeletion.scss";

const AccountDeletion = () => {
  // const { code } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');
  const [confirmDeletion, setConfirmDeletion] = useState(false);
  const [token, setToken] = useState('');

  const handleLogin = async (event) => {
    event.preventDefault();
    setError(null);

    try {
      const response = await login(username, password);
      setToken(response.data.access);
      setUserData(response.data.user_data)
      setLoggedIn(true);
    } catch (error) {
      setError('Login failed. Please check your username and password.');
    }
  };

  const handleDeleteAccount = async () => {
    setError(null);

    if (!confirmDeletion) {
      setError('You must confirm account deletion before proceeding.');
      return;
    }

    try {
      const responseMessage = await deleteAccount(username, password, token);
      setMessage(responseMessage);
      navigate("/")
      alert('Your account has been successfully deleted.');
    } catch (error) {
      setError('Account deletion failed. Please try again.');
    }
  };

  const truncateFileName = (fileName, maxLength) => {
    if (fileName.length > maxLength) {
      return fileName.slice(0, maxLength) + "...";
    }
    return fileName;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", { month: "short", day: "numeric" });
  };
  return (
    <div className="page-wrapper">
      {loggedIn ?
        <div className="confirming-box">
          <div className="delete-account-container">
            <div className="profile-section">
              <img src={userData.image} alt={`${userData.first_name}'s profile`} className="profile-image" />
              <div className="user-info">
                <h2>{`${userData.first_name} ${userData.last_name}`}</h2>
                <p>Username: {userData.username}</p>
                <p>Email: {userData.email}</p>
              </div>
            </div>

            <div className="warning-section">
              <p>
                Warning: Deleting your account will permanently remove all your data. You will not be able
                to recover this information once deleted.
              </p>
            </div>
          </div>

          {/* Button Section */}
          <div className="main-btn-section">
            <button onClick={() => navigate("/")} className="cancel-btn">
              Cancel
            </button>
            <button onClick={() => handleDeleteAccount()} className="delete-btn">
              Delete Account
            </button>
          </div>
        </div> :
        <div className="delete-account-login-form">
          <h2>Delete Your Account</h2>
          <p>
            Warning: Once you delete your account, you will not be able to recover any data.
            All your information will be permanently removed from our servers.
          </p>
          <form onSubmit={handleLogin}>
            <div>
              <label>Username</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div style={{display: "flex", justifyContent: "start"}}>
              <input
                type="checkbox"
                checked={confirmDeletion}
                onChange={() => setConfirmDeletion(!confirmDeletion)}
              />
              <label> I understand that this action is irreversible and I want to proceed.</label>
            </div>
            <button type="submit" disabled={!confirmDeletion}>
              Delete Account
            </button>
          </form>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {message && <p>{message}</p>}
        </div>}
    </div>
  );
};

export default AccountDeletion;
