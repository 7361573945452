import React, {useEffect} from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Header from '../components/Services/Header';
import Achievements from '../components/Services/Achievements';
import AboutUs from '../components/Services/AboutUs';
import FlagshipProduct from '../components/Services/FlagshipProduct';
import '../assets/styles/pages/Services.scss';
import Services from '../components/Services/Services';
import SaaSOfferings from '../components/Services/SaaSOfferings';
import WhyChooseUs from '../components/Services/WhyChooseUs';
import Contact from '../components/Services/Contact';
import SaasQuality from '../components/Services/SaasQuality';

// const services = [
//   {
//     title: 'Identity Theft Protection',
//     description:
//       'Our multi-layered approach to safeguarding personal and professional information',
//     icon: TheftBg,
//     features: [
//       'AI-driven behavioral analysis to detect unusual patterns',
//       'Blockchain for tamper-proof record-keeping',
//       'Advanced encryption for data protection',
//     ],
//   },
//   {
//     title: 'Cybersecurity Solutions',
//     description:
//       'We offer a comprehensive suite of cybersecurity services like Vulnerability Assessments and Penetration Testing, Security Information and Event Management (SIEM), Incident Response and Forensics, Employee Cybersecurity Training',
//     icon: Cybersecuritybg,
//     features: [
//       'Machine learning for predictive threat detection',
//       'Quantum-resistant encryption protocols',
//       'AI-powered SIEM for real-time threat analysis',
//     ],
//   },
//   {
//     title: 'Website Development',
//     description:
//       'Our web development services cater to businesses of all sizes using latest frameworks like React.js, Vue.js, Angular',
//     icon: Websitedevbg,
//     features: [
//       'Custom Website Design and Development',
//       'E-commerce Solutions',
//       'Content Management Systems',
//       'Web Application Development',
//       'SEO Optimization',
//     ],
//   },
//   {
//     title: 'Mobile App Development',
//     description:
//       'We build high-performance mobile apps with seamless user experiences.',
//     icon: Mobilebg,
//     features: [
//       'Native iOS and Android Development',
//       'Cross-platform App Development (React Native, Flutter)',
//       'UI/UX Design and App Store Optimization',
//       'Ongoing Maintenance and Support',
//       'Backend Integration (Firebase, AWS Mobile)',
//     ],
//   },
//   {
//     title: 'Server Development',
//     description:
//       'We provide scalable, secure, and high-performance server solutions.',
//     icon: Serverbg,
//     features: [
//       'Microservices Architecture',
//       'Database Design and Optimization',
//       'Load Balancing and Auto-scaling',
//       'Cloud Platforms (AWS, Azure, Google Cloud)',
//     ],
//   },
//   {
//     title: 'Software Testing',
//     description:
//       'We ensure the quality, performance, and security of your software.',
//     icon: Testingbg,
//     features: [
//       'Functional and Performance Testing',
//       'Security and Usability Testing',
//       'Automated Regression Testing',
//       'Test Automation (Selenium, Appium, JUnit)',
//       'Performance Testing (Apache JMeter, Gatling)',
//     ],
//   },
// ];

const Portfolio = () => {


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-container">
      <Header />
      <Achievements />
      <AboutUs />
      <FlagshipProduct />
      <Services />
      <SaaSOfferings />
      <SaasQuality />
      <WhyChooseUs />
      {/* <Contact /> */}
      
    </div>
  );
};

export default Portfolio;
