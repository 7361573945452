import React from 'react';
import AntroLogoWhite from "../../assets/images/antrologowhite.png";
import TwitterLogo from "../../assets/images/mdi_twitter.png";
import InstagramLogo from "../../assets/images/mdi_instagram.png";
import FacebookLogo from "../../assets/images/ri_facebook-fill.png";
import LinkedInLogo from "../../assets/images/ri_linkedin-fill.png";
import GoTop from "../../assets/images/footer/Frame 626816.png";
import "../../assets/styles/components/footer.scss";

const Footer = () => {

    // Function to scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // This adds a smooth scrolling animation
        });
    };

    return (
        <div className='main-container-footer'>
            {/* <div className="newsletter-section">
                <div className='subscribe-heading-footer'>
                    <span className='subscribe-firstpart-heading-footer'>Subscribe To Our </span>
                    <span className='subscribe-secondpart-heading-footer'>Newsletter</span>
                </div>
                <div className="newsletter-form">
                    <input type="email" placeholder="Email Address" />
                    <button>SUBSCRIBE</button>
                </div>
            </div> */}

            <div className='footer-btnup'>
                <div className="footer">
                    <div className="footer-content">
                        <div className="footer-logo">
                            <img src={AntroLogoWhite} alt="Antro Logo" />
                            <p>
                                An innovative cybersecurity solutions company specializing in identity and access management.
                                We offer real-time, process-driven solutions designed to protect against identity theft and impersonation.
                            </p>
                        </div>

                        <div className="quick-links">
                            <h3>Quick Links</h3>
                            <ul>
                                <li><a href="/about-us">About</a></li>
                                <li>
                                    <a href="/product-info" style={{fontWeight: "bold", color: "#3c93fb"}}>
                                        Antro-PAI <span className="featured-badge">🔥 Featured</span>
                                    </a>
                                </li>
                                <li><a href="/contact-us">Contact</a></li>
                            </ul>
                        </div>

                        <div className="contact-info">
                            <h3>Contact Us</h3>
                            <p>Email Address: <a className='contact-email' href="mailto:itadmin@antrocorp.com">itadmin@antrocorp.com</a></p>
                            Location:
                            <p className='contact-address'>201, Ram Krishna Nivas Towers, Sanjeeva Reddy Nagar, Hyderabad, Telangana - 500038</p>
                        </div>

                        <div className="social-links">
                            <h3>Follow</h3>
                            <div className="icons">
                                {/* <a href="https://x.com/i/flow/login" target="_blank" rel="noopener noreferrer"><img src={TwitterLogo} alt="Twitter" /></a>
                                <a href="https://www.facebook.com/login/" target="_blank" rel="noopener noreferrer"><img src={FacebookLogo} alt="Facebook" /></a>
                                <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><img src={InstagramLogo} alt="Instagram" /></a> */}
                                <a href="https://www.linkedin.com/company/antro-pai-technologies-pvt-ltd/?originalSubdomain=in" target="_blank" rel="noopener noreferrer"><img src={LinkedInLogo} alt="LinkedIn" /></a>
                            </div>
                        </div>
                    </div>

                    <div className="footer-bottom">
                        <p>Copyright 2023. All Rights Reserved.</p>
                        <div className="footer-links">
                            <a href="/terms">Terms & conditions</a>
                            <a href="/privacy-policy">Privacy policy</a>
                        </div>
                    </div>
                </div>

                {/* Go to Top Button */}
                {/* <div className='toggle-up-footer' onClick={scrollToTop}>
                    <img src={GoTop} alt="Go to top" />
                </div> */}
            </div>
        </div>
    );
};

export default Footer;
