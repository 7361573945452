import React, { useState, useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import Services1 from '../../assets/images/portfolio/services1.png';
import '../../assets/styles/pages/services/Services.scss';

const services = [
  {
    title: 'Identity Theft Protection',
    description: `
      Our multi-layered approach to safeguarding personal and professional information includes:
      - Continuous Dark Web Monitoring
      - Real-time Fraud Alerts
      - Identity Recovery Services
      - $1 Million Identity Theft Insurance

      Technologies:
      - AI-driven behavioral analysis to detect unusual patterns
      - Blockchain for tamper-proof record-keeping
      - Advanced encryption for data protection
    `,
  },
  {
    title: 'Cybersecurity Solutions',
    description: `
      Comprehensive cybersecurity services designed to protect businesses from cyber threats.
    `,
  },
  {
    title: 'Website Development',
    description: `
      Build robust and scalable websites to enhance your online presence.
    `,
  },
  {
    title: 'Mobile App Development',
    description: `
      Custom mobile app development for iOS and Android platforms.
    `,
  },
  {
    title: 'Server Development',
    description: `
      High-performance server solutions for your business.
    `,
  },
  {
    title: 'Software Testing',
    description: `
      Ensure software quality and performance with our comprehensive testing services.
    `,
  },
];

const Services = () => {
  const [expandedIndex, setExpandedIndex] = useState(null); // Set default to null for first load
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false, amount: 0.5 });

  const handleExpand = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  return (
    <div className="services" ref={ref}>
      <div className='services-sub-heading'>WHAT WE PROVIDE</div>
      <motion.h2
        initial={{ opacity: 0, y: -50 }}
        animate={isInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8 }}
      >
        Our Services
      </motion.h2>

      <div className="services__content">
        {/* Left image */}
        <motion.div
          className="services__image"
          initial={{ opacity: 0, x: -50 }}
          animate={isInView ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 1 }}
        >
          <img src={Services1} alt="Services" />
        </motion.div>

        {/* Right accordion */}
        <div className="services__accordion">
          {services.map((service, index) => (
            <div key={index} className={`service-item ${expandedIndex === index ? 'expanded' : ''}`}>
              <div className="service-title" onClick={() => handleExpand(index)}>
                {service.title}
              </div>
              {expandedIndex === index && (
                <motion.div
                  className="service-description"
                  initial={{ opacity: 0, height: 0 }}
                  animate={isInView && expandedIndex === index ? { opacity: 1, height: 'auto' } : {}}
                  transition={{ duration: 0.3 }}
                >
                  <p>{service.description}</p>
                </motion.div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
