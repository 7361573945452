import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import "../../assets/styles/components/navbar.scss";
import AntroLogo from "../../assets/images/antrologo.png";

const Navbar = () => {
    const location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const getNavLinkClass = (path) => {
        return location.pathname === path ? 'nav-link active' : 'nav-link';
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // Effect to close menu automatically when screen size is greater than 720px
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 720) {
                setIsMenuOpen(false); // Automatically close menu
            }
        };

        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setIsMenuOpen(false); // Close menu whenever location changes
    }, [location]);

    return (
        <div className="navbar">
            <div className="navbar-left">
                <img src={AntroLogo} alt="Antro Logo" className="logo" />
            </div>

            <button className="hamburger" onClick={toggleMenu}>
                <span className="hamburger-icon">&#9776;</span>
            </button>

            <div className={`navbar-center ${isMenuOpen ? 'open' : ''}`}>
                <ul className="nav-list">
                    <li><Link to="/" className={getNavLinkClass("/")}>Home</Link></li>
                    <li><Link to="/about-us" className={getNavLinkClass("/about-us")}>About</Link></li>
                    <li><Link to="/product-info" className={getNavLinkClass("/product-info")}>Antro-PAI</Link></li>
                    <li><Link to="/portfolio" className={getNavLinkClass("/portfolio")}>Portfolio</Link></li>
                    <li><Link to="/contact-us" className={getNavLinkClass("/contact-us")}>Contact</Link></li>
                </ul>
            </div>

            <div className="navbar-right">
                {/* <button className="download-app-btn">Download App</button> */}
            </div>
        </div>
    );
};

export default Navbar;
